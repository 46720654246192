<template>
  <!-- 种族选择 -->
  <div class="inlet">
    <div class="inlet-scroll">
      <div class="inlet-cont">
        <div class="contlist">
          <div class="contlist-img">
            <img src="../../assets/img/inlet-lfet.png" alt="">
          </div>
          <p style="padding: 75px 20px 0 45px;">内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容</p>
        </div>
        <div class="contlist">
          <div class="contlist-img">
            <img src="../../assets/img/inlet-right.png" alt="">
          </div>
          <p style="padding: 75px 45px 0 20px;">内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'technologyInlet',
  data(){
    return{

    }
  },
  mounted(){

  },
  methods:{
    
  }
}
</script>
<style scoped>
.inlet{
  width: 100%;
  height: 100%;
  position: fixed;
  background: url(../../assets/img/bg.jpg)center;
  overflow-y: scroll;
}
.inlet-scroll{
  padding: 40px 20px;
  margin-bottom: 100px;
}
.inlet-cont{
  display: flex;
}
.contlist{
  flex: 1;
}
.contlist-img{
  height: 650px;
}
.contlist img{
  width: 100%;
  height: 100%;
}
.contlist p{
  font-size: 18px;
  font-weight: 350;
  color: #ffffff;
}
</style>